<template>
  <div class="flex_blcok">
    <div class="form_wrapper">
      <div class="form_container">
        <div class="title_container">
          <h2>
            {{ lang == "uz" ? "Bemor ma'lumotlari" : "Информация о пациенте" }}
          </h2>
        </div>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">
              {{ lang == "uz" ? "Bemor IDsi:" : "Идентификатор пациента:" }}
            </div>
            <div class="boldText">{{ patient_id }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">{{ lang == "uz" ? "Ismi:" : "Имя:" }}</div>
            <div class="boldText">{{ first_name }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">
              {{ lang == "uz" ? "Familiyasi:" : "Фамилия:" }}
            </div>
            <div class="boldText">{{ last_name }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">
              {{ lang == "uz" ? "Sharifi:" : "Отчество:" }}
            </div>
            <div class="boldText">{{ middle_name }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">{{ lang == "uz" ? "Jinsi:" : "Пол:" }}</div>
            <div class="boldText">{{ gender }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">
              {{ lang == "uz" ? "Tug'ilgan sanasi:" : "Дата рождения" }}
            </div>
            <div class="boldText">{{ birth_date }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">
              {{ lang == "uz" ? "Manzili:" : "Адрес" }}
            </div>
            <div class="boldText">{{ address }}</div>
          </div>
        </a-card>
        <a-card style="margin-bottom: 15px" :bordered="false" class="widget-1">
          <div class="flex_cards">
            <div style="width: 35%">
              {{ lang == "uz" ? "Telefon raqami:" : "Номер телефона:" }}
            </div>
            <div class="boldText">{{ phone_number }}</div>
          </div>
        </a-card>
      </div>
    </div>
    <div class="form_wrapper" style="width: 1000px">
      <div class="form_container">
        <div class="title_container">
          <h2>
            {{ lang == "uz" ? "Tahlil natijalari" : "Результаты анализа" }}
          </h2>
        </div>
        <div class="flex_section">
          <div class="row clearfix flex_section_item">
            <div class="">
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Vazni :" : "Вес :" }}
                  </div>
                  <div class="boldText">{{ weight }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Bo'yi :" : "Рост :" }}
                  </div>
                  <div class="boldText">{{ growth }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Ketle indeksi :" : "Индекс Кетле :" }}
                  </div>
                  <div class="boldText">{{ quetelet_index }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Hozirda klinik sinovda ishtirok etmoqdami ?"
                        : "Участвует ли в настоящее время в клиническом исследовании ?"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? clinical_trial == 1
                          ? "yo'q"
                          : clinical_trial == 2
                          ? "ha"
                          : "noma'lum"
                        : clinical_trial == 1
                        ? "нет"
                        : clinical_trial == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "O'tmishdagi miokard infarkti :"
                        : "Инфаркт миокарда в прошлом :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? myocardial_infarction == 1
                          ? "yo'q"
                          : myocardial_infarction == 2
                          ? "ha"
                          : "noma'lum"
                        : myocardial_infarction == 1
                        ? "нет"
                        : myocardial_infarction == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Stenokardiya :" : "Стенокардия :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? angina_pectoris == 1
                          ? "yo'q"
                          : angina_pectoris == 2
                          ? "ha"
                          : "noma'lum"
                        : angina_pectoris == 1
                        ? "нет"
                        : angina_pectoris == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Yurak yetishmovchiligi :"
                        : "Застойная сердечная недостаточность :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? heart_failure == 1
                          ? "yo'q"
                          : heart_failure == 2
                          ? "ha"
                          : "noma'lum"
                        : heart_failure == 1
                        ? "нет"
                        : heart_failure == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Insult :" : "Инсульт :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? stroke == 1
                          ? "yo'q"
                          : stroke == 2
                          ? "ha"
                          : "noma'lum"
                        : stroke == 1
                        ? "нет"
                        : stroke == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Periferik arteriya kasalliklari :"
                        : "Заболевание периферических артерий :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? peripheral_arterial == 1
                          ? "yo'q"
                          : peripheral_arterial == 2
                          ? "ha"
                          : "noma'lum"
                        : peripheral_arterial == 1
                        ? "нет"
                        : peripheral_arterial == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Surunkali buyrak etishmovchiligi :"
                        : "Хроническая почечная недостаточность :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? renal_failure == 1
                          ? "yo'q"
                          : renal_failure == 2
                          ? "ha"
                          : "noma'lum"
                        : renal_failure == 1
                        ? "нет"
                        : renal_failure == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Surunkali o'pka kasalligi :"
                        : "Хроническое заболевание легких :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? lung_disease == 1
                          ? "yo'q"
                          : lung_disease == 2
                          ? "ha"
                          : "noma'lum"
                        : lung_disease == 1
                        ? "нет"
                        : lung_disease == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Perkutan koronar aralashuvlar :"
                        : "Чрезкожные коронарные вмешательства :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? percutaneous_coronary == 1
                          ? "yo'q"
                          : percutaneous_coronary == 2
                          ? "ha"
                          : "noma'lum"
                        : percutaneous_coronary == 1
                        ? "нет"
                        : percutaneous_coronary == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Koronar shuntirlash :"
                        : "Коронарное шунтирование :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? coronary_bypass == 1
                          ? "yo'q"
                          : coronary_bypass == 2
                          ? "ha"
                          : "noma'lum"
                        : coronary_bypass == 1
                        ? "нет"
                        : coronary_bypass == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Koronar stenozlar > 50% :"
                        : "Коронарные стенозы > 50% :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? coronary_stenoses == 1
                          ? "yo'q"
                          : coronary_stenoses == 2
                          ? "ha"
                          : "noma'lum"
                        : coronary_stenoses == 1
                        ? "нет"
                        : coronary_stenoses == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <!-- <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Bemorning o'tkir koronar pitologiya haqidagi bilimlari :"
                        : "Наличие знаний пациента об острой каронарной питологии :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? acute_coronary == 1
                          ? "yo'q"
                          : acute_coronary == 2
                          ? "ha"
                          : acute_coronary == 3
                          ? "sezilarli darajada emas"
                          : "noma'lum"
                        : acute_coronary == 1
                        ? "нет"
                        : acute_coronary == 2
                        ? "да"
                        : acute_coronary == 3
                        ? "не значительно"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card> -->
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Chekish :" : "Курение :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? smoking == 1
                          ? "chekmagan"
                          : smoking == 2
                          ? "chekadi"
                          : smoking == 3
                          ? "tashlagan"
                          : "noma'lum"
                        : smoking == 1
                        ? "не курил"
                        : smoking == 2
                        ? "курит"
                        : smoking == 3
                        ? "бросил"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Qandli diabet :" : "Сахарный диабет :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? diabetes_mellitus == 1
                          ? "yo'q"
                          : diabetes_mellitus == 2
                          ? "ha(parhez)"
                          : diabetes_mellitus == 3
                          ? "ha(tabletkalar)"
                          : diabetes_mellitus == 4
                          ? "ha(insulin)"
                          : diabetes_mellitus == 5
                          ? "yangi tashxis qo'yilgan"
                          : "noma'lum"
                        : diabetes_mellitus == 1
                        ? "нет"
                        : diabetes_mellitus == 2
                        ? "да (диета)"
                        : diabetes_mellitus == 3
                        ? "да (таблетки)"
                        : diabetes_mellitus == 4
                        ? "да (инсулин)"
                        : diabetes_mellitus == 5
                        ? "вновь диагностированный"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Arterial gipertenziya :"
                        : "Артериальная гипертензия :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? arterial_hypertension == 1
                          ? "yo'q"
                          : arterial_hypertension == 2
                          ? "ha"
                          : "noma'lum"
                        : arterial_hypertension == 1
                        ? "нет"
                        : arterial_hypertension == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <!-- <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    Oilada gipertoniya kasalligiga chalinganlar mavjudmi:
                  </div>
                  <div class="boldText">
                    {{
                      family_hypertension == 1
                        ? "yo'q"
                        : family_hypertension == 2
                        ? "ha"
                        : "noma'lum"
                    }}
                  </div>
                </div>
              </a-card> -->
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Giperxolesterinemiya :"
                        : "Гипер-холестеринемия :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? hypercholesterolemia == 1
                          ? "yo'q"
                          : hypercholesterolemia == 2
                          ? "ha"
                          : "noma'lum"
                        : hypercholesterolemia == 1
                        ? "нет"
                        : hypercholesterolemia == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Giperxolesterinemiyaning avlodida mavjudligi :"
                        : "Семейный анмнез гиперхолестеринемии :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? family_hypercholester == 1
                          ? "yo'q"
                          : family_hypercholester == 2
                          ? "ha"
                          : "noma'lum"
                        : family_hypercholester == 1
                        ? "нет"
                        : family_hypercholester == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Yurak ishemik kasalligining avlodida mavjudligi :"
                        : "Семейный анамнез ИБС :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? family_coronary_artery == 1
                          ? "yo'q"
                          : family_coronary_artery == 2
                          ? "ha"
                          : "noma'lum"
                        : family_coronary_artery == 1
                        ? "нет"
                        : family_coronary_artery == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Gipertoniyaning avlodida mavjudligi :"
                        : "Семейный анализ ГБ  :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? family_analysis_hd == 1
                          ? "yo'q"
                          : family_analysis_hd == 2
                          ? "ha"
                          : "noma'lum"
                        : family_analysis_hd == 1
                        ? "нет"
                        : family_analysis_hd == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <!-- <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "V.S.ning oilaviy tarixi :"
                        : "Семейный анамнез по ВС :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? family_vs == 1
                          ? "yo'q"
                          : family_vs == 2
                          ? "ha"
                          : "noma'lum"
                        : family_vs == 1
                        ? "нет"
                        : family_vs == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card> -->
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Qandli diabet avlodida mavjudligi :"
                        : "Семейный анамнез по сахарному диабету:"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? family_diabetes == 1
                          ? "yo'q"
                          : family_diabetes == 2
                          ? "ha"
                          : "noma'lum"
                        : family_diabetes == 1
                        ? "нет"
                        : family_diabetes == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Aspirin :" : "Аспирин :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? aspirin == 1
                          ? "yo'q"
                          : aspirin == 2
                          ? "ha"
                          : "noma'lum"
                        : aspirin == 1
                        ? "нет"
                        : aspirin == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Boshqa antitrombotik dorilar :"
                        : "Другие антитромботи-ческие препараты :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? antithrombotic_drugs == 1
                          ? "yo'q"
                          : antithrombotic_drugs == 2
                          ? "tiklopidin"
                          : antithrombotic_drugs == 3
                          ? "klopidogrel"
                          : antithrombotic_drugs == 4
                          ? "boshqa"
                          : "noma'lum"
                        : antithrombotic_drugs == 1
                        ? "нет"
                        : antithrombotic_drugs == 2
                        ? "тиклопидин"
                        : antithrombotic_drugs == 3
                        ? "клопидогрель"
                        : antithrombotic_drugs == 4
                        ? "другой"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz" ? "Antikoagulyantlar :" : "Анти-коагулянты :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? anticoagulants == 1
                          ? "yo'q"
                          : anticoagulants == 2
                          ? "Vit K antagonistlari"
                          : anticoagulants == 3
                          ? "og'iz trombin ingibitorlari"
                          : anticoagulants == 4
                          ? "boshqa"
                          : "noma'lum"
                        : anticoagulants == 1
                        ? "нет"
                        : anticoagulants == 2
                        ? "антагонисты  Vit К"
                        : anticoagulants == 3
                        ? "пероральные ингибиторы тромбина"
                        : anticoagulants == 4
                        ? "другой"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
            </div>
          </div>
          <div class="row clearfix flex_section_item">
            <div class="">
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Beta-blokerlar :" : "Бета-блокаторы :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? beta_blockers == 1
                          ? "yo'q"
                          : beta_blockers == 2
                          ? "ha"
                          : "noma'lum"
                        : beta_blockers == 1
                        ? "нет"
                        : beta_blockers == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Angiotensinga aylantiruvchi ferment ingibatorlari :"
                        : "Ингибиторы АПФ :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? ace_inhibitors == 1
                          ? "yo'q"
                          : ace_inhibitors == 2
                          ? "ha"
                          : "noma'lum"
                        : ace_inhibitors == 1
                        ? "нет"
                        : ace_inhibitors == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Angiotensin II retseptorlari antagonistlari :"
                        : "Антагонисты рецепторов к ангиотензину II :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? angiotensin_2 == 1
                          ? "yo'q"
                          : angiotensin_2 == 2
                          ? "ha"
                          : "noma'lum"
                        : angiotensin_2 == 1
                        ? "нет"
                        : angiotensin_2 == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Statinlar :" : "Статины :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? statins == 1
                          ? "yo'q"
                          : statins == 2
                          ? "ha"
                          : "noma'lum"
                        : statins == 1
                        ? "нет"
                        : statins == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <!-- <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    Lipidlarni kamaytiradigan statin bo'lmagan dorilar:
                  </div>
                  <div class="boldText">
                    {{
                      lipid_lowering == 1
                        ? "yo'q"
                        : lipid_lowering == 2
                        ? "ezetimibe"
                        : lipid_lowering == 3
                        ? "fibratlar"
                        : lipid_lowering == 4
                        ? "boshqa"
                        : "noma'lum"
                    }}
                  </div>
                </div>
              </a-card> -->
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Nitratlar :" : "Нитраты :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? nitrates == 1
                          ? "yo'q"
                          : nitrates == 2
                          ? "ha"
                          : "noma'lum"
                        : nitrates == 1
                        ? "нет"
                        : nitrates == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Ca kanal blokerlari :"
                        : "Блокаторы Са каналов :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? channel_blockers == 1
                          ? "yo'q"
                          : channel_blockers == 2
                          ? "ha"
                          : "noma'lum"
                        : channel_blockers == 1
                        ? "нет"
                        : channel_blockers == 2
                        ? "да"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz" ? "Asosiy simptom :" : "Основной симптом :"
                    }}
                  </div>
                  <div class="boldText">
                    {{ main_symptom }}
                    <!-- {{
                      lang == "uz"
                        ? main_symptom.includes("1")
                          ? "simptomsiz"
                          : main_symptom.includes("2")
                          ? "ko'krakdagi og'riq"
                          : main_symptom.includes("3")
                          ? "nafas qisilishi"
                          : main_symptom.includes("4")
                          ? "zaiflik"
                          : main_symptom.includes("5")
                          ? "hushidan ketish"
                          : main_symptom.includes("6")
                          ? "qon aylanishini to'xtatish / to'satdan o'limni to'xtatdi"
                          : main_symptom.includes("7")
                          ? "boshqa"
                          : main_symptom.includes("8")
                          ? "noma'lum"
                          : "sovuq ter"
                        : main_symptom.includes("1")
                        ? "бессимптомный"
                        : main_symptom.includes("2")
                        ? "боль в груди"
                        : main_symptom.includes("3")
                        ? "одышка"
                        : main_symptom == 4
                        ? "слабость"
                        : main_symptom == 5
                        ? "синкопа"
                        : main_symptom == 6
                        ? "остановка кровообращиения/прекращенная внезапная смерть"
                        : main_symptom == 7
                        ? "другое"
                        : main_symptom == 8
                        ? "не известно"
                        : "холодный пот"
                    }} -->
                  </div>
                </div>
              </a-card>
              <!-- <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz" ? "Asosiy sindrom :" : "Основной синдром :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? major_syndrome == 1
                          ? "simptomsiz"
                          : major_syndrome == 2
                          ? "ko'krakdagi og'riq"
                          : major_syndrome == 3
                          ? "ko'krak og'rig'i + nafas qisilishi"
                          : major_syndrome == 4
                          ? "ko'krak og'rig'i + zaiflik"
                          : major_syndrome == 5
                          ? "ko'krak og'rig'i + sovuq ter"
                          : major_syndrome == 6
                          ? "nafas qisilishi"
                          : major_syndrome == 7
                          ? "nafas qisilishi + zaiflik"
                          : major_syndrome == 8
                          ? "hushidan ketish"
                          : major_syndrome == 9
                          ? "zaiflik"
                          : major_syndrome == 10
                          ? "ko'krak og'rig'i + nafas qisilishi + zaiflik"
                          : major_syndrome == 11
                          ? "ko'krak og'rig'i + nafas qisilishi + zaiflik + sovuq ter"
                          : major_syndrome == 12
                          ? "ko'krak og'rig'i + nafas qisilishi + sovuq ter"
                          : major_syndrome == 13
                          ? "ko'krak og'rig'i + zaiflik + senkop"
                          : major_syndrome == 14
                          ? "ko'krak og'rig'i + zaiflik + sovuq ter"
                          : major_syndrome == 15
                          ? "nafas qisilishi + zaiflik"
                          : major_syndrome == 16
                          ? "nafas qisilishi + zaiflik + sovuq ter"
                          : "zaiflik + sovuq ter"
                        : major_syndrome == 1
                        ? "бессимптомный"
                        : major_syndrome == 2
                        ? "боль в груди"
                        : major_syndrome == 3
                        ? "боль в груди + одышка"
                        : major_syndrome == 4
                        ? "боль в груди + слабость"
                        : major_syndrome == 5
                        ? "боль в груди + холодный пот"
                        : major_syndrome == 6
                        ? "одышка"
                        : major_syndrome == 7
                        ? "одышка + слабость"
                        : major_syndrome == 8
                        ? "синкопа"
                        : major_syndrome == 9
                        ? "слабость"
                        : major_syndrome == 10
                        ? "боль в груди + одышка + слабость"
                        : major_syndrome == 11
                        ? "боль в груди + одышка + слабость + холод пот"
                        : major_syndrome == 12
                        ? "боль в груди + одышка + холод пот"
                        : major_syndrome == 13
                        ? "боль в груди + слабост + синкоп"
                        : major_syndrome == 14
                        ? "боль в груди + слабост + холод пот"
                        : major_syndrome == 15
                        ? "одышки + слобасть"
                        : major_syndrome == 16
                        ? "одышки + слобасть + холод пот"
                        : "слабость + холод пот"
                    }}
                  </div>
                </div>
              </a-card> -->
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Yurak urishi :" : "ЧСС" }}
                  </div>
                  <div class="boldText">{{ heart_rate }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Sistolik qon bosimi :" : "Сист.АД" }}
                  </div>
                  <div class="boldText">{{ syst_bp }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Diastolik qon bosimi :" : "Диаст.АД" }}
                  </div>
                  <div class="boldText">{{ diast_bp }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "EKG ritmi :" : "Ритм ЭКГ :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? ecg_rhythm == 1
                          ? "sinus"
                          : ecg_rhythm == 2
                          ? "atriyal fibrilatsiya / chayqalish"
                          : ecg_rhythm == 3
                          ? "EKS ritmi"
                          : ecg_rhythm == 4
                          ? "boshqa"
                          : "noma'lum"
                        : ecg_rhythm == 1
                        ? "синусовый"
                        : ecg_rhythm == 2
                        ? "мерцание/трепетание предсердий"
                        : ecg_rhythm == 3
                        ? "ритм ЭКС"
                        : ecg_rhythm == 4
                        ? "другой"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "QRS kompleksining shakli :"
                        : "Форма комплекса QRS :"
                    }}
                  </div>
                  <div class="boldText">
                    {{ qrs_complex }}
                    <!-- {{
                      lang == "uz"
                        ? qrs_complex == 1
                          ? "norma"
                          : qrs_complex == 2
                          ? "LBBB"
                          : qrs_complex == 3
                          ? "RBBB"
                          : qrs_complex == 4
                          ? "boshqa"
                          : "noma'lum"
                        : qrs_complex == 1
                        ? "норма"
                        : qrs_complex == 2
                        ? "БЛНПГ"
                        : qrs_complex == 3
                        ? "БПНПГ"
                        : qrs_complex == 4
                        ? "другой"
                        : "не известно"
                    }} -->
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz" ? "ST-T o'zgarishlari :" : "Изменения ST-T :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? st_t_changes == 1
                          ? "norma"
                          : st_t_changes == 2
                          ? "ST balandligi"
                          : st_t_changes == 3
                          ? "ST depressiyasi"
                          : st_t_changes == 4
                          ? "T inversiyasi (ST balandligi yo'q)"
                          : st_t_changes == 5
                          ? "boshqa"
                          : st_t_changes == 6
                          ? "noma'lum"
                          : st_t_changes == 7
                          ? "Q to'lqinining o'zgarishi"
                          : "Q to'lqini o'zgarishlarining lokalizatsiyasi"
                        : st_t_changes == 1
                        ? "норма"
                        : st_t_changes == 2
                        ? "элевация ST"
                        : st_t_changes == 3
                        ? "депрессия ST"
                        : st_t_changes == 4
                        ? "инверсия Т (без подъема ST)"
                        : st_t_changes == 5
                        ? "другой"
                        : st_t_changes == 6
                        ? "не известно"
                        : st_t_changes == 7
                        ? "изменение зубца Q"
                        : "локализация изменений зубца Q"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "EKG o'zgarishlari lokalizatsiyasi :"
                        : "Локализация изменений на ЭКГ :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? ecg_changes == 1
                          ? "old"
                          : ecg_changes == 2
                          ? "orqa"
                          : ecg_changes == 3
                          ? "boshqa"
                          : ecg_changes == 4
                          ? "noma'lum"
                          : "yo'q"
                        : ecg_changes == 1
                        ? "передняя"
                        : ecg_changes == 2
                        ? "задние"
                        : ecg_changes == 3
                        ? "другая"
                        : ecg_changes == 4
                        ? "не известно"
                        : "нет"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Troponin T darajasi :"
                        : "Уровень тропонина Т :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? troponin_t_level == 1
                          ? "o'lchanmagan"
                          : troponin_t_level == 2
                          ? "ko'tarilgan"
                          : troponin_t_level == 3
                          ? "baland emas"
                          : "noma'lum"
                        : troponin_t_level == 1
                        ? "не измерялся"
                        : troponin_t_level == 2
                        ? "повышен"
                        : troponin_t_level == 3
                        ? "не повышен"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Troponin I darajasi :"
                        : "Уровень тропонина I :"
                    }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? troponin_i_level == 1
                          ? "o'lchanmagan"
                          : troponin_i_level == 2
                          ? "ko'tarilgan"
                          : troponin_i_level == 3
                          ? "baland emas"
                          : "noma'lum"
                        : troponin_i_level == 1
                        ? "не измерялся"
                        : troponin_i_level == 2
                        ? "повышен"
                        : troponin_i_level == 3
                        ? "не повышен"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "MV-KFK :" : "Уровень массы МВ-КФК :" }}
                  </div>
                  <div class="boldText">
                    {{
                      lang == "uz"
                        ? mv_cpk == 1
                          ? "o'lchanmagan"
                          : mv_cpk == 2
                          ? "ko'tarilgan"
                          : mv_cpk == 3
                          ? "baland emas"
                          : "noma'lum"
                        : mv_cpk == 1
                        ? "не измерялся"
                        : mv_cpk == 2
                        ? "повышен"
                        : mv_cpk == 3
                        ? "не повышен"
                        : "не известно"
                    }}
                  </div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Qabul qilishda ALT qiymati :"
                        : "Значение АЛТ при поступлении :"
                    }}
                  </div>
                  <div class="boldText">{{ alt_value }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Qabul qilishda AST qiymati :"
                        : "Значение АСТ при поступлении :"
                    }}
                  </div>
                  <div class="boldText">{{ ast_value }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{
                      lang == "uz"
                        ? "Umumiy xolesterin :"
                        : "Общий холестерин :"
                    }}
                  </div>
                  <div class="boldText">{{ total_cholesterol }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Triglitseridlar :" : "Триглицериды :" }}
                  </div>
                  <div class="boldText">{{ triglycerides }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Kreatinin :" : "Креатинин :" }}
                  </div>
                  <div class="boldText">{{ treatinine }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Glyukoza :" : "Глюкоза :" }}
                  </div>
                  <div class="boldText">{{ glucose }}</div>
                </div>
              </a-card>
              <a-card
                style="margin-bottom: 10px"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%">
                    {{ lang == "uz" ? "Gemoglobin :" : "Гемоглобин :" }}
                  </div>
                  <div class="boldText">{{ hemoglobin }}</div>
                </div>
              </a-card>
              <!-- <a-card
                style="margin-top: 50px; border: 1px solid lightgrey"
                :bordered="false"
                class="widget-1"
              >
                <div class="flex_cards">
                  <div style="width: 90%; font-weight: 700">XULOSA :</div>
                  <div
                    class="boldText"
                    style="font-weight: 700; color: #1890ff"
                  >
                    {{ calculatedDisease }}
                  </div>
                </div>
              </a-card> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "@/axios.js";
export default {
  data() {
    return {
      analysis_id: this.$route.query.analysis_id,
      patient_id: null,
      lang: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      birth_date: null,
      address: null,
      phone_number: null,
      gender: null,
      cpt: null,
      eia: null,
      fbs: null,
      mhra: null,
      nmvcf: null,
      rbp: null,
      rer: null,
      sc: null,
      spests: null,
      stdierr: null,
      thal: null,
      weight: null,
      growth: null,
      quetelet_index: null,
      clinical_trial: null,
      myocardial_infarction: null,
      angina_pectoris: null,
      heart_failure: null,
      stroke: null,
      peripheral_arterial: null,
      lung_disease: null,
      renal_failure: null,
      percutaneous_coronary: null,
      coronary_bypass: null,
      coronary_stenoses: null,
      acute_coronary: null,
      smoking: null,
      diabetes_mellitus: null,
      arterial_hypertension: null,
      family_hypertension: null,
      hypercholesterolemia: null,
      family_hypercholester: null,
      family_coronary_artery: null,
      family_analysis_hd: null,
      family_vs: null,
      family_diabetes: null,
      aspirin: null,
      antithrombotic_drugs: null,
      anticoagulants: null,
      beta_blockers: null,
      ace_inhibitors: null,
      angiotensin_2: null,
      statins: null,
      lipid_lowering: null,
      nitrates: null,
      channel_blockers: null,
      calculatedDisease: null,
      main_symptom: "",
      major_syndrome: null,
      heart_rate: null,
      syst_bp: null,
      diast_bp: null,
      ecg_rhythm: null,
      qrs_complex: "",
      st_t_changes: null,
      ecg_changes: null,
      troponin_t_level: null,
      troponin_i_level: null,
      mv_cpk: null,
      alt_value: null,
      ast_value: null,
      total_cholesterol: null,
      triglycerides: null,
      treatinine: null,
      glucose: null,
      hemoglobin: null,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
    axios.get("/api/analysis/" + this.$route.query.analysis_id).then((res) => {
      this.patient_id = res.data.patientId;
      (this.weight = res.data.weight),
        (this.growth = res.data.growth),
        (this.quetelet_index = res.data.quetelet_index),
        (this.clinical_trial = res.data.clinical_trial),
        (this.myocardial_infarction = res.data.myocardial_infarction),
        (this.angina_pectoris = res.data.angina_pectoris),
        (this.heart_failure = res.data.heart_failure),
        (this.stroke = res.data.stroke),
        (this.peripheral_arterial = res.data.peripheral_arterial),
        (this.lung_disease = res.data.lung_disease),
        (this.renal_failure = res.data.renal_failure),
        (this.percutaneous_coronary = res.data.percutaneous_coronary),
        (this.coronary_bypass = res.data.coronary_bypass),
        (this.coronary_stenoses = res.data.coronary_stenoses),
        (this.acute_coronary = res.data.acute_coronary),
        (this.smoking = res.data.smoking),
        (this.diabetes_mellitus = res.data.diabetes_mellitus),
        (this.arterial_hypertension = res.data.arterial_hypertension),
        (this.family_hypertension = res.data.family_hypertension),
        (this.hypercholesterolemia = res.data.hypercholesterolemia),
        (this.family_hypercholester = res.data.family_hypercholester),
        (this.family_coronary_artery = res.data.family_coronary_artery),
        (this.family_analysis_hd = res.data.family_analysis_hd),
        (this.family_vs = res.data.family_vs),
        (this.family_diabetes = res.data.family_diabetes),
        (this.aspirin = res.data.aspirin),
        (this.antithrombotic_drugs = res.data.antithrombotic_drugs),
        (this.anticoagulants = res.data.anticoagulants),
        (this.beta_blockers = res.data.beta_blockers),
        (this.ace_inhibitors = res.data.ace_inhibitors),
        (this.angiotensin_2 = res.data.angiotensin_2),
        (this.statins = res.data.statins),
        (this.lipid_lowering = res.data.lipid_lowering),
        (this.nitrates = res.data.nitrates),
        (this.channel_blockers = res.data.channel_blockers),
        // (this.main_symptom = res.data.main_symptom),
        (this.major_syndrome = res.data.major_syndrome),
        (this.heart_rate = res.data.heart_rate),
        (this.syst_bp = res.data.syst_BP),
        (this.diast_bp = res.data.diast_BP),
        (this.ecg_rhythm = res.data.ecg_rhythm),
        // (this.qrs_complex = res.data.qrs_complex),
        (this.st_t_changes = res.data.st_T_changes),
        (this.ecg_changes = res.data.ecg_changes),
        (this.troponin_t_level = res.data.troponin_T_level),
        (this.troponin_i_level = res.data.troponin_I_level),
        (this.mv_cpk = res.data.mv_CPK),
        (this.alt_value = res.data.alt_value),
        (this.ast_value = res.data.ast_value),
        (this.total_cholesterol = res.data.total_cholesterol),
        (this.triglycerides = res.data.triglycerides),
        (this.treatinine = res.data.treatinine),
        (this.glucose = res.data.glucose),
        (this.hemoglobin = res.data.hemoglobin);
      if (res.data.main_symptom && res.data.main_symptom.includes("1")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "simptomsiz, "
            : this.main_symptom + "бессимптомный, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("2")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "ko'krakdagi og'riq, "
            : this.main_symptom + "боль в груди, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("3")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "nafas qisilishi, "
            : this.main_symptom + "одышка, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("4")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "zaiflik, "
            : this.main_symptom + "слабость, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("5")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "hushidan ketish, "
            : this.main_symptom + "синкопа, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("6")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom +
              "qon aylanishini to'xtatish / to'satdan o'limni to'xtatdi, "
            : this.main_symptom +
              "остановка кровообращиения/прекращенная внезапная смерть, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("7")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "boshqa, "
            : this.main_symptom + "другое, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("8")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "noma'lum, "
            : this.main_symptom + "не известно, ";
      }
      if (res.data.main_symptom && res.data.main_symptom.includes("9")) {
        this.main_symptom =
          this.lang == "uz"
            ? this.main_symptom + "sovuq ter, "
            : this.main_symptom + "холодный пот, ";
      }
      if (res.data.qrs_complex && res.data.qrs_complex.includes("1")) {
        this.qrs_complex =
          this.lang == "uz"
            ? this.qrs_complex + "normal, "
            : this.qrs_complex + "нормал, ";
      }
      if (res.data.qrs_complex && res.data.qrs_complex.includes("2")) {
        this.qrs_complex =
          this.lang == "uz"
            ? this.qrs_complex + "Gis to'plamining chap oyoqcha blokadasi, "
            : this.qrs_complex + "БЛНПГ (блокада левой ножки пучка Гиса), ";
      }
      if (res.data.qrs_complex && res.data.qrs_complex.includes("3")) {
        this.qrs_complex =
          this.lang == "uz"
            ? this.qrs_complex + "Gis to'plamining o'ng oyoqcha blokadasi, "
            : this.qrs_complex + "БЛНПГ (блокада правой ножки пучка Гиса), ";
      }
      if (res.data.qrs_complex && res.data.qrs_complex.includes("4")) {
        this.qrs_complex =
          this.lang == "uz"
            ? this.qrs_complex + "to'liq blokada, "
            : this.qrs_complex + "Полная блокада, ";
      }
      if (res.data.qrs_complex && res.data.qrs_complex.includes("5")) {
        this.qrs_complex =
          this.lang == "uz"
            ? this.qrs_complex + "to'liqsiz blokada, "
            : this.qrs_complex + "Неполная блокада, ";
      }
      if (res.data.qrs_complex && res.data.qrs_complex.includes("6")) {
        this.qrs_complex =
          this.lang == "uz"
            ? this.qrs_complex + "boshqa holat, "
            : this.qrs_complex + "другое, ";
      }
      if (res.data.qrs_complex && res.data.qrs_complex.includes("7")) {
        this.qrs_complex =
          this.lang == "uz"
            ? this.qrs_complex + "noma'lum, "
            : this.qrs_complex + "не известно, ";
      }
      axios.get("/api/patients/" + this.patient_id).then((res) => {
        this.first_name = res.data.firstName;
        this.last_name = res.data.lastName;
        this.middle_name = res.data.middleName;
        this.birth_date = res.data.birthDate;
        this.address = res.data.address;
        this.phone_number = res.data.phoneNumber;
        this.gender =
          this.lang == "uz"
            ? res.data.gender == 1
              ? "ERKAK"
              : "AYOL"
            : res.data.gender == 1
            ? "Мужчина"
            : "Женщина";
      });
    });
  },
  methods: {
    setGender(val) {
      this.gender = val;
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: #1890ff;
$black: #000000;
$grey: #1890ff;

.boldText {
  font-weight: 600;
  color: black;
  text-align: right;
}
.flex_blcok {
  display: flex;
  flex-wrap: wrap;
}
.flex_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex_section_item {
  width: 46%;
  margin: 10px;
}
.flex_cards {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.add_btn {
  background: $yellow;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: darken($yellow, 7%);
  }
  &:focus {
    background: darken($yellow, 7%);
  }
}
.inputField {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0.375rem 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-family: "Montserrat";
}
.clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
  }
}
.form_wrapper {
  background: #fff;
  width: 700px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 25px;
  margin: 3% auto 0;
  position: relative;
  z-index: 1;
  border-top: 5px solid $yellow;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.8s 0.6s ease-out forwards;
  animation: expand 0.8s 0.6s ease-out forwards;
  opacity: 0;
  h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
  }
  .title_container {
    text-align: center;
    padding-bottom: 15px;
  }
  h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
  }
  label {
    font-size: 12px;
  }
  .row {
    margin: 10px -15px;
    > div {
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
  .col_half {
    width: 50%;
    float: left;
  }
  .input_field {
    position: relative;
    margin-bottom: 20px;
    -webkit-animation: bounce 0.6s ease-out;
    animation: bounce 0.6s ease-out;
    > span {
      position: absolute;
      left: 0;
      top: 0;
      color: #333;
      height: 100%;
      border-right: 1px solid $grey;
      text-align: center;
      width: 30px;
      > i {
        padding-top: 10px;
      }
    }
  }
  .textarea_field {
    > span {
      > i {
        padding-top: 10px;
      }
    }
  }
  input {
    &[type="text"],
    &[type="date"],
    &[type="email"],
    &[type="number"] {
      width: 100%;
      padding: 8px 10px 9px 10px;
      height: 35px;
      border: 1px solid $grey;
      box-sizing: border-box;
      outline: none;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    &[type="text"]:hover,
    &[type="date"]:hover,
    &[type="number"]:hover {
      background: #fafafa;
    }
    &[type="text"]:focus,
    &[type="number"]:focus,
    &[type="date"]:focus {
      -webkit-box-shadow: 0 0 2px 1px #1890ff;
      -moz-box-shadow: 0 0 2px 1px #1890ff;
      box-shadow: 0 0 2px 1px #1890ff;
      border: 1px solid $yellow;
      background: #fafafa;
    }
    &[type="submit"] {
      background: $yellow;
      height: 35px;
      line-height: 35px;
      border-radius: 5px;
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-size: 1.1em;
      margin-bottom: 10px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: darken($yellow, 7%);
      }
      &:focus {
        background: darken($yellow, 7%);
      }
    }
    &[type="checkbox"],
    &[type="radio"] {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}
.form_container {
  .row {
    .col_half.last {
      border-left: 1px solid $grey;
    }
  }
}
.checkbox_option {
  label {
    margin-right: 1em;
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.5em;
      vertical-align: -2px;
      border: 2px solid $grey;
      padding: 0.12em;
      background-color: transparent;
      background-clip: content-box;
      transition: all 0.2s ease;
    }
    &:after {
      border-right: 2px solid black;
      border-top: 2px solid black;
      content: "";
      height: 20px;
      left: 2px;
      position: absolute;
      top: 7px;
      transform: scaleX(-1) rotate(135deg);
      transform-origin: left top;
      width: 7px;
      display: none;
    }
  }
  input {
    &:hover + label:before {
      border-color: black;
    }
    &:checked + label {
      &:before {
        border-color: black;
      }
      &:after {
        -moz-animation: check 0.8s ease 0s running;
        -webkit-animation: check 0.8s ease 0s running;
        animation: check 0.8s ease 0s running;
        display: block;
        width: 7px;
        height: 20px;
        border-color: black;
      }
    }
  }
}
.radio_option {
  label {
    margin-right: 1em;
    &:before {
      content: "";
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.5em;
      border-radius: 100%;
      vertical-align: -3px;
      border: 2px solid $grey;
      padding: 0.15em;
      background-color: transparent;
      background-clip: content-box;
      transition: all 0.2s ease;
    }
  }
  input {
    &:hover + label:before {
      border-color: black;
    }
    &:checked + label:before {
      background-color: black;
      border-color: black;
    }
  }
}
.select_option {
  position: relative;
  width: 100%;
  select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: #7b7b7b;
    border: 1px solid $grey;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
    &::-ms-expand {
      display: none;
    }
    &:hover,
    &:focus {
      color: black;
      background: #fafafa;
      border-color: black;
      outline: none;
    }
  }
}
.select_arrow {
  position: absolute;
  top: calc(50% - 4px);
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 8px 5px 0 5px;
  border-style: solid;
  border-color: #7b7b7b transparent transparent transparent;
}

.select_option select {
  &:hover + .select_arrow,
  &:focus + .select_arrow {
    border-top-color: black;
  }
}
.credit {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  color: $yellow;
  a {
    color: darken($yellow, 7%);
  }
}
@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 7px;
  }
  50% {
    height: 20px;
    width: 7px;
  }
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 7px;
  }
  50% {
    height: 20px;
    width: 7px;
  }
}

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@media (max-width: 600px) {
  .form_wrapper {
    .col_half {
      width: 100%;
      float: none;
    }
  }
  .bottom_row {
    .col_half {
      width: 50%;
      float: left;
    }
  }
  .form_container {
    .row {
      .col_half.last {
        border-left: none;
      }
    }
  }
  .remember_me {
    padding-bottom: 20px;
  }
  .flex_section {
    display: block;
  }
  .flex_section_item {
    width: 100%;
  }
  .form_wrapper {
    width: 400px !important;
  }
}
</style>